<template>
    <main>
        <section>
            <h1>
                Mijn albums
            </h1>

            <p v-if="userAlbums.length === 0">
                Je hebt op dit moment nog geen albums tot je beschikking.
            </p>

            <div v-else class="grid">
                <album-preview v-for="(a, id) in userAlbums" :key="`ua-${id}`" :album="a" :webp="webp" />
            </div>
        </section>


        <section v-if="otherAlbums.length > 0">
            <h1>
                Albums van anderen
            </h1>

            <div class="grid">
                <album-preview v-for="(a, id) in otherAlbums" :key="`oa-${id}`" :album="a" :webp="webp" />
            </div>
        </section>
    </main>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

import AlbumPreview from '@/components/AlbumPreview.vue';

var vm;

export default {
    props: {
        page: Object,
        webp: Boolean,
    },


    components: {
        AlbumPreview,
    },


    computed: {
        ...mapGetters([
            'albums',
            'user',
        ]),


        otherAlbums()
        {
            let oa = [];

            for (let i = 0; i < vm.albums.length; i++)
            {
                let a = vm.albums[i];

                if (a.variables.client != vm.user.id && (a.hidemenu || vm.user.admin == 1) && a.sections.length > 0)
                {
                    oa.push(a);
                }
            }

            return oa;
        },


        userAlbums()
        {
            let ua = [];

            for (let i = 0; i < vm.albums.length; i++)
            {
                let a = vm.albums[i];

                if (a.variables.client == vm.user.id && a.sections.length > 0)
                {
                    ua.push(a);
                }
            }

            return ua;
        },
    },


    methods: {
        ...mapActions([
            'loadAlbums',
        ]),
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        if (vm.user)
        {
            vm.loadAlbums();
        }
    },
}
</script>