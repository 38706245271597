<template>

    <header :key="nav.length">
        <a href="https://www.pascalmaranus.nl" target="_blank" class="logo">
            <img src="images/logo.svg" :alt="siteName" width="30" height="30" />
        </a>

        <nav :class="showNav ? 'open' : ''" :style="`--items: ${nav.length + 1}`">

            <router-link v-for="(item, id) in nav" :key="['item', id].join('-')" :to="to(item)" :title="item.name">
                <i class="fa-sharp fa-light" :class="item.icon"></i>

                <span>
                    {{ item.name }}
                </span>
            </router-link>

            <a href="#" v-if="user" @click.prevent="$emit('logout')">
                <i class="fa-sharp fa-light fa-arrow-right-from-bracket"></i>

                <span>
                    Log uit
                </span>
            </a>
            <a href="#" class="login" v-else @click.prevent="login = true">
                <i class="fa-sharp fa-light fa-arrow-right-to-bracket"></i>

                <span>
                    Log in
                </span>
            </a>
        </nav>

        <a href="https://www.instagram.nl/pascalmaranus.nl" class="social" target="_blank" title="Pascal Maranus op Instagram">
            <i class="fab fa-instagram"></i>
        </a>

        <a href="#" class="menu-toggle" @click.prevent="toggleNav">
            <transition name="fade" mode="out-in">
                <i v-if="!showNav" class="fa-sharp fa-light fa-bars"></i>
                <i v-else class="fa-sharp fa-light fa-times"></i>
            </transition>
        </a>
    </header>


    <transition name="fade" mode="out-in">
        <div v-if="login" class="modal" @click.self="login = false">
            <div class="modal-body">
                <login-form @toggle="login = !login" />
            </div>
        </div>
    </transition>
    
</template>


<script>
import { mapGetters } from 'vuex';

import pageData from '@/hooks/page.js';
import useFunctions from '@/hooks/functions.js';

import LoginForm from '@/components/LoginForm.vue'

var vm;

export default {
    props: {
        showNav: Boolean,
    },


    components: {
        LoginForm,
    },
    

    data()
    {
        return {
            login: false,
        }
    },

    
    computed: {
        ...mapGetters([
            'pages',
            'siteName',
            'structure',
            'user',
        ]),


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.structure.length; i++)
            {
                let item = vm.structure[i];

                if (item.hidemenu == 0 || vm.user)
                {
                    nav.push(item);
                }
            }

            return nav;
        },
    },


    methods: {
        logoutUser()
        {
            vm.$emit('logout');
        },


        toggleNav()
        {
            vm.$emit('toggle');
        },
    },


	setup()
	{
		const { page } = pageData();
		const { to } = useFunctions();

		return {
			page,
            to,
		};
	},


    created()
    {
        vm = this;
    },    
}
</script>