<template>
	<transition-group name="load" mode="out-in">
		<article v-if="loaded" key="path" @click="onClick">
			<teleport to="head">
				<title>
					{{ meta.title }}
				</title>
				
				<meta name="description" :content="meta.description">
				<meta name="keywords" :content="meta.keywords">
				
				<meta property="og:description" :content="meta.description">
				<meta property="og:title" :content="meta.title" />
				<meta property="og:image" :content="meta.image" />
			</teleport>
			

			<app-header
                :showNav="showNav"
                @logout="logoutUser"
                @toggle="toggleNav"
            />
			

            <router-view v-if="page" v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component
                        v-if="hasAccess"
						:is="Component"
                        :class="hasTouch ? 'touch' : 'no-touch'"
						:key="$route.path"
						:page="page"
						:photo="photo"
                        :webp="webp"
						@logout="logoutUser"
					/>

                    <main v-else>
                        <section>
                            <login-form>
                                <p v-html="accessMessage" />
                            </login-form>
                        </section>
                    </main>

				</transition>
			</router-view>

            
            <div v-else-if="!user" class="modal">
                <div class="modal-body">
                    <login-form>
                        <p v-html="accessMessage" />
                    </login-form>
                </div>
            </div>

		</article>


		<loader-element v-else key="loader" />

	</transition-group>
</template>


<script>
import supportsWebP from 'supports-webp';

import { mapActions, mapGetters } from 'vuex';

import usePage from '@/hooks/page.js';

import AppHeader from '@/components/AppHeader.vue';
import LoaderElement from '@/components/LoaderElement.vue';
import LoginForm from '@/components/LoginForm.vue';

var vm;

export default {
	components: {
		AppHeader,
		LoaderElement,
        LoginForm,
	},

	
	data()
	{
		return {
            hasTouch: null,
			showNav: false,
            webp: true,
		};
	},


	computed: {
		...mapGetters([
            'imgBase',
			'pages',
			'siteName',
			'structure',
			'user',
		]),


        accessMessage()
        {
            let msg = 'Om deze pagina te mogen bekijken dien je eerst in te loggen.';

            if (vm.$route.name == 'AlbumPage')
            {
                msg = 'Dit album is niet publiekelijk toegankelijk. Is dit jouw album? Log dan in. Als het album met je gedeeld is, neem dan contact op met de eigenaar.';
            }

            return msg;
        },


        hasAccess()
        {
            if (vm.page && !vm.page.hidemenu) return true;
            else if (vm.user !== null) return true;
            
            return false;
        },


		loaded()
		{
			let s = Object.keys(vm.structure),
				p = Object.keys(vm.pages);
			
			return s.length > 0 && p.length > 0;
		},


		meta()
		{
			// If no page is loaded yet, return false
			if (!vm.page || Object.keys(vm.page).length === 0 || Object.prototype.hasOwnProperty.call(vm.page, 'temp')) return false;

			let view = vm.page;


			let title = view.title;
			title += ' | ' + vm.siteName;

			let img = view.hasImage ? view.variables.image : 'img/icons/android-chrome-512x512.png';
			
			let meta = {
				description: view.description,
				keywords: view.keywords,
				title: title,
				image: vm.baseUrl + img,
			};
			

			return meta;
		},
	},


	methods: {
		...mapActions([
			'_initialize',
			'logout',
            'updateScreenWidth',
        ]),


        detectTouch()
        {
            try {
                document.createEvent('TouchEvent');
                vm.hasTouch = true;
            }
            catch (e)
            {
                vm.hasTouch = false;
            }
        },


		logoutUser()
		{
			// Do a minor delay for the general "onClick" listener on the DOM
			setTimeout(() => {
				vm.logout();
			}, 13);
		},


        onResize()
        {
            vm.detectTouch();
            vm.updateScreenWidth(parseInt(window.innerWidth));
        },


		scrollReveal()
		{
            vm.detectTouch();
		},


        toggleNav()
        {
            vm.showNav = !vm.showNav;
        },
	},


	setup()
	{
		const { page } = usePage();


		return {
			page,
		};
	},


	created()
	{
        vm = this;


        // Run the resize functions initially
        vm.onResize();


        // Make sure webp support is checked
        supportsWebP.then(supported => {
            if(!supported)
            {
                document.documentElement.classList.remove('webp');
                document.documentElement.classList.add('no-webp');
                vm.webp = false;
            }
            else
            {
                document.documentElement.classList.remove('no-webp');
                document.documentElement.classList.add('webp');
                vm.webp = true;
            }
        });


        // Initialize the actual application
        vm._initialize({
            auth: localStorage.getItem('client'),
            ck: 'clients',
            uri: window.location.pathname.slice(1),
        })


		window.addEventListener('resize', vm.onResize);
	},


	unmounted()
	{
		window.removeEventListener('resize', vm.onResize);
	},


    watch: {
        $route ()
        {
            vm.showNav = false;
        },
    },
};
</script>


<style lang="scss">
@import 'assets/css/all.min.css';
@import 'assets/css/sharp-light.min.css';
@import 'assets/css/sharp-regular.min.css';
@import 'assets/css/sharp-solid.min.css';
@import 'assets/scss/app.scss';
</style>
