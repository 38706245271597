<template>
    <main>
        <section class="columns">
            <div class="content" v-html="page.content" />

            <contact-form :page="page" />
        </section>
    </main>
</template>


<script>
import ContactForm from '@/components/ContactForm.vue'

export default {
    props: {
        page: Object,
    },


    components: {
        ContactForm,
    },
}
</script>