<template>
    <main>
        <section>
            <div v-html="page.content" />
            
            <div v-if="!user" class="tools">
                <a href="#" class="button" @click.prevent="login = true">
                    <i class="fal fa-user"></i>
                    
                    <span>
                        Inloggen
                    </span>
                </a>
            </div>
        </section>


        <transition name="fade" mode="out-in">
            <div v-if="login" class="modal" @click.self="login = false">
                <div class="modal-body">
                    <login-form @toggle="login = !login" />
                </div>
            </div>
        </transition>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

import LoginForm from '@/components/LoginForm.vue'

// var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        LoginForm,
    },


    data()
    {
        return {
            login: false,
        }
    },


    computed: {
        ...mapGetters([
            'user',
        ]),
    },


    // created()
    // {
    //     vm = this;
    // },
}
</script>