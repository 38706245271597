<template>
    <main>
        <section v-html="page.content" />
    </main>
</template>


<script>
export default {
    props: {
        page: Object,
    },
}
</script>