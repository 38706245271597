import { createStore } from "vuex";
// import { useRoute } from "vue-router";

import axios from 'axios';

var base = 'https://api.pascalmaranus.nl/',
    baseUrl = base.replace('api', 'clients');

export default createStore({
    state: {
        albums: [],
        base: base,
        baseUrl: baseUrl,
        duration: 650,
        isDev: process.env.NODE_ENV == 'development',
        pages: {},
        screenWidth: 0,
        siteName: 'Pascal Maranus',
        sizes: [
            {
                'key': 'imageSmall',
                'restriction': '(max-width: 1023px)',
            },
            {
                'key': 'image',
                'restriction': '(min-width: 1024px)',
            },
        ],
        structure: {},
        user: localStorage.getItem('client'),
    },


    getters: {
        albums: state => {
            return state.albums;
        },


        base: state => {
            return state.base;
        },


        baseUrl: state => {
            return state.baseUrl;
        },


        duration: state => {
            return state.duration;
        },


        imgBase: state => {
            return state.isDev ? state.baseUrl : '';
        },


        isDev: state => {
            return state.isDev;
        },


        pages: state => {
            return state.pages;
        },


        screenWidth: state => {
            return state.screenWidth;
        },


        siteName: state => {
            return state.siteName;
        },


        sizes: state => {
            return state.sizes;
        },


        structure: state => {
            return state.structure;
        },


        user: state => {
            let u = state.user;
            
            if (u) u = JSON.parse(atob(u));

            return u;
        },
    },


    mutations: {
        setAlbums(state, value) {
            state.albums = value;
        },


        setPages(state, value) {
            state.pages = value;
        },


        setScreenWidth(state, value) {
            state.screenWidth = value;
        },        


        setStructure(state, value) {
            state.structure = value;
        },


        setUser(state, value) {
            if (value)
            {
                localStorage.setItem('client', value);
                state.user = value;
            }
            else
            {
                localStorage.removeItem('client');
                state.user = null;
            }
        },
    },


    actions: {
        _initialize: async (context, payload) => {
            try {
                const response = await axios.get(base + 'initialize', { params: payload });
                context.commit('setAlbums', response.data.albums);
                context.commit('setPages', response.data.pages);
                context.commit('setStructure', response.data.structure);
            } catch (error) {
                console.log(error);
            }
        },


        // getUserData: async ({ commit }, payload) => {
        //     try {
        //         const response = await axios.get(base + 'user', { params: payload });
        //         commit('setUser', response.data);
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },


        loadAlbums: async ({commit}) => {
            try {
                const response = await axios.get(base + 'user/albums', { params: {
                    auth: localStorage.getItem('client'),
                    // uri: payload,
                    uri: window.location.pathname.slice(1),
                }});
                commit('setAlbums', response.data);
            } catch (error) {
                console.log(error);
            }
        },


        logout: ({ commit }) => {
            localStorage.removeItem('client')
            commit('setUser', null)
        },


        updateAlbums: ({ commit }, payload) => {
            return new Promise((resolve) => {
                commit('setAlbums', payload);
                resolve();
            });
        },


        updateScreenWidth: ({ commit }, payload) => {
            commit('setScreenWidth', payload)
        },


        updateUser: ({ commit }, payload) => {
            return new Promise((resolve) => {
                commit('setUser', payload);
                resolve();
            });
        },
    },
});
