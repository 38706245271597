<template>
    <div class="grid-item album-preview">
        <router-link :to="toAlbum(album)">
            <figure>
                <picture>
                    <source v-for="(img, id) in images" :key="id" :srcset="img.src" :type="img.type" />
        
                    <img width="640" height="960" :src="imgBase + album.variables.imageFallback" :alt="album.pagetitle" />
                </picture>
    
                <figcaption>
                    {{ album.pagetitle }}
                </figcaption>
            </figure>
        </router-link>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    props: {
        album: Object,
    },


    data()
    {
        return {
            images: [],
        }
    },


    computed: {
        ...mapGetters([
            'imgBase',
        ]),
    },


    methods: {
        toAlbum(a)
        {
            return {
                name: 'AlbumPage',
                params: {
                    alias: a.alias,
                },
            };
        },
    },


    created()
    {
        vm = this;

        vm.images = [
            {
                src: vm.imgBase + vm.album.variables.image,
                type: 'image/webp',
            },
            {
                src: vm.imgBase + vm.album.variables.imageFallback,
                type: 'image/jpeg',
            },
        ]
    },
}
</script>