<template>
    <main>
        <section class="album-page">
            <h1 data-before="Album">
                {{ page.pagetitle }}
            </h1>


            <div class="tools" v-if="user">
                <form>
                    <label for="order">
                        Sorteren
                    </label>

                    <select v-model="order">
                        <option value="default">
                            Standaard
                        </option>

                        <option value="rating-desc">
                            Beoordeling (hoog-laag)
                        </option>

                        <option value="rating-asc">
                            Beoordeling (laag-hoog)
                        </option>
                    </select>
                </form>

                <div class="toggle">
                    <strong>
                        Privé
                    </strong>

                    <toggle-switch :status="!page.hidemenu" @update="updateAlbumStatus" />

                    <strong>
                        Openbaar
                    </strong>
                </div>

                <div class="buttons">
                    <a href="#" class="button primary" :class="{ disabled: generatingZip }" @click.prevent="downloadAlbum">
                        <i v-if="!generatingZip" class="fa-sharp fa-light fa-arrow-down-to-line"></i>
                        <i v-else class="fa-sharp fa-light fa-spinner-third fa-spin"></i>

                        <span v-if="!generatingZip">
                            Album downloaden
                        </span>
                        <span v-else>
                            Je foto's worden ingepakt
                        </span>
                    </a>
                </div>
            </div>


            <div class="album" v-for="(row, id) in rows" :key="id">
                <div class="photo"
                    v-for="(p, rid) in row" :key="rid"
                    :class="[p.variables.orientation, p.hidemenu ? 'private' : null].join(' ')"
                    :data-index="p.id"
                    :data-rating="p.variables.hasOwnProperty('rating') ? p.variables.rating : 0"
                    :data-status="p.hidemenu ? 1 : 0"
                >
                    <a href="#" @click.prevent="selectPhoto(p)">
                        <figure>
                            <picture>
                                <source v-for="(img, id) in getImages(p)" :key="id" :srcset="img.src" :type="img.type" />
                
                                <img height="960" :width="page.variables.orientation == 'landscape' ? (960 * 1.5) : (960 / 1.5)" :src="imgBase + page.variables.imageFallback" :alt="page.pagetitle" />
                            </picture>
                        </figure>
                    </a>

                    
                    <div class="hover" v-if="user">
                        <div class="content">
                            <div class="tool photo-id">
                                <i class="fa-sharp fa-regular fa-image"></i>
                                
                                <strong>
                                    {{ p.pagetitle }}
                                </strong>
                            </div>
    
                            <div class="tool">
                                <span class="rating-container">
                                    <a href="#" class="rating" v-for="i in 5" :key="i" @click.prevent="toggleRating($event, i)">
                                        <i class="fa-sharp fa-star"></i>
                                    </a>
                                </span>
                            </div>

                            <div class="tool toggle">
                                <strong>
                                    Privé
                                </strong>
        
                                <span class="toggle-switch" :class="p.hidemenu ? 'off' : 'on'" @click="togglePhotoStatus($event)">
                                    <div class="switch"></div>
                                </span>

                                <strong>
                                    Openbaar
                                </strong>
                            </div>
    
                            <div class="tool buttons">
                                <a href="#" class="button secondary" @click.prevent="selectPhoto(p)">
                                    <i class="fa-sharp fa-light fa-magnifying-glass"></i>

                                    <span>
                                        Bekijken
                                    </span>
                                </a>

                                <a :href="p.variables.imageFallback" class="button white" :download="`${p.pagetitle}.jpg`">
                                    <i class="fa-sharp fa-light fa-arrow-down-to-line"></i>
                                
                                    <span>
                                        Downloaden
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <transition name="fade" mode="out-in">
            
            <div class="modal lightbox" v-if="photo" @click.self="closeLightbox">
                <a href="#" class="close" @click.prevent="closeLightbox">
                    <i class="far fa-times-circle"></i>
                </a>

                <transition name="fade" mode="out-in">
                    <picture class="lightbox-image" :key="photoID">
                        <img :src="`${photo.url}`" :alt="photo.title" />
                    </picture>
                </transition>

                <div class="tools" v-if="page.sections.length > 1">
                    <a href="#" @click.prevent="updatePhotoID(-1)">
                        <i class="far fa-angle-left"></i>
                    </a>

                    <span>
                        {{ photoID + 1 }}
                        /
                        {{ page.sections.length }}
                    </span>

                    <a href="#" @click.prevent="updatePhotoID(1)">
                        <i class="far fa-angle-right"></i>
                    </a>
                </div>

                <div class="preloader">
                    <img :key="prevPhoto.url" :src="`${prevPhoto.url}`" rel="preload" />
                    <img :key="nextPhoto.url" :src="`${nextPhoto.url}`" rel="preload" />
                </div>
            </div>

        </transition>

    </main>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import axios from 'axios';

import ToggleSwitch from '@/components/ToggleSwitch.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        ToggleSwitch,
    },


    data()
    {
        return {
            generatingZip: false,
            height: 480,
            order: 'default',
            photoID: -1,
            selection: [],
        }
    },


    computed: {
        ...mapGetters([
            'base',
            'baseUrl',
            'imgBase',
            'screenWidth',
            'user',
        ]),


        nextPhoto()
        {
            return vm.getPhoto(1)
        },


        photo()
        {
            return vm.getPhoto(0);
        },


        photos()
        {
            if (!vm.page) return [];
            if (vm.order == 'default') return vm.page.sections;

            let labeled = {},
                output = [];

            // Create an object to order
            for (let i = 0; i < vm.page.sections.length; i++)
            {
                let p = vm.page.sections[i],
                    key = Object.prototype.hasOwnProperty.call(p.variables, 'rating') ? [p.variables.rating] : [0];
                
                key.push(p.menuindex, p.id);
                key = key.join('_');

                labeled[key] = p;
            }


            // Sort the array
            let keys = Object.keys(labeled);
            keys.sort();

            if (vm.order.indexOf('-desc') >= 0) keys = keys.reverse();

            
            // Fill the output array
            for (let j = 0; j < keys.length; j++)
            {
                output.push(labeled[keys[j]])
            }


            return output;
        },


        prevPhoto()
        {
            return vm.getPhoto(-1)
        },


        rows()
        {
            let length = vm.photos.length,
                limit = 2,
                onRowID = 0,
                rowID = 0,
                rows = [[]];


            // Determine the limit per row
            if (vm.screenWidth >= 1920) limit = 4;
            else if (vm.screenWidth >= 1024) limit = 3;


            // Create the rows and add photos
            for (let i = 0; i < length; i++)
            {
                let p = vm.photos[i];

                if (p.hidemenu == 1 && !vm.user) continue;

                onRowID++;

                rows[rowID].push(p);

                if ((onRowID === limit || p.variables.break == 1) && i < length - 1)
                {
                    rowID++;
                    onRowID = 0;
                    rows.push([]);
                }
            }

            
            /**
             * If the last row only has a single image, take one from the previous to add.
             * But only if there's more than 2 images per row
             **/
            if (rows[rowID].length === 1 && limit > 2)
            {
                // Get the previous and last items
                let prevRow = rows[rowID - 1],
                    previous = prevRow[limit - 1],
                    last = rows[rowID][0];

                // Replace the last row with the two of them
                rows[rowID] = [previous, last];

                // Remove the previous item from the second to last row
                prevRow.pop();
                rows[rowID - 1] = prevRow;
            }

            return rows;
        },
    },


    methods: {
        ...mapActions([
            'updateAlbums',
        ]),
        
        
        async downloadAlbum()
        {
            try {
                vm.generatingZip = true;

                const response = await axios.post(vm.base + 'album/download', {
                    auth: localStorage.getItem('client'),
                    id: vm.page.id,
                })

                vm.generatingZip = false;
                window.open(`${vm.baseUrl}${response.data}`, '_blank');
            } catch (error) {
                console.log(error);
            }
        },


        async updateAlbumStatus(value)
        {
            try {
                const response = await axios.post(vm.base + 'album/update', {
                    auth: localStorage.getItem('client'),
                    id: vm.page.id,
                    fields: {
                        hidemenu: value ? 0 : 1,
                    },
                });

                vm.updateAlbums(response.data)
            } catch (error) {
                console.log(error);
            }
        },


        async updatePhoto(photo)
        {
            try {
                const response = await axios.post(vm.base + 'photo/update', {
                    auth: localStorage.getItem('client'),
                    data: photo,
                });

                vm.updateAlbums(response.data)
            } catch (error) {
                console.log(error);
            }
        },


        closeLightbox()
        {
            vm.photoID = -1;
        },


        getImages(p)
        {
            return [
                {
                    src: vm.imgBase + p.variables.imageMobile,
                    type: 'image/webp',
                },
                {
                    src: vm.imgBase + p.variables.imageFallback,
                    type: 'image/jpeg',
                },
            ]
        },


        getPhoto(modifier)
        {
            if (vm.photoID < 0 || vm.photoID >= vm.page.sections.length) return null;

            let id = vm.photoID + modifier;

            if (id < 0) id = vm.page.sections.length - 1;
            else if (id >= vm.page.sections.length) id = 0;

            let p = vm.page.sections[id],
                img = vm.screenWidth < 1366 ? 'imageMobile' : 'image';

            return {
                title: p.pagetitle,
                url: vm.imgBase + p.variables[img],
            }
        },


        // getPhotoId(ph)
        // {
        //     for (let i = 0; i < vm.page.sections.length; i++)
        //     {
        //         let p = vm.page.sections[i];

        //         if (p.id == ph.id) return i;
        //     }

        //     return null;
        // },


        selectPhoto(p)
        {
            for (let i = 0; i < vm.page.sections.length; i++)
            {
                if (vm.page.sections[i].id == p.id) vm.photoID = i;
            }
        },


        togglePhotoStatus(e)
        {
            let el = e.currentTarget,
                toggle = e.currentTarget;

            while (el.className.indexOf('photo') < 0)
            {
                el = el.parentNode;
                
                if (el.className.indexOf('toggle-switch') >= 0) toggle = el;
            }

            let value = el.dataset.status == 1 ? 0 : 1;
            el.dataset.status = value;
            toggle.classList.toggle('on');
            toggle.classList.toggle('off');

            vm.updatePhoto({
                id: el.dataset.index,
                fields: {
                    hidemenu: value,
                },
            })
        },


        toggleRating(e, rating)
        {
            let el = e.currentTarget;

            while (el.className.indexOf('photo') < 0)
            {
                el = el.parentNode;
            }

            let value = el.dataset.rating == rating ? 0 : rating;
            el.dataset.rating = value;

            vm.updatePhoto({
                id: el.dataset.index,
                variables: {
                    rating: value,
                },
            })
        },

        
        updatePhotoID(modifier)
        {
            let id = vm.photoID + modifier;

            if (id < 0) id = vm.page.sections.length - 1;
            else if (id >= vm.page.sections.length) id = 0;

            vm.photoID = id;
        },
    },


    created()
    {
        vm = this;
    },
}
</script>