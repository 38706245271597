import { createRouter, createWebHistory } from "vue-router";

import Account from "@/views/AccountPage.vue";
import Album from "@/views/AlbumPage.vue";
import Albums from "@/views/AlbumsPage.vue";
import Contact from "@/views/ContactPage.vue";
// import Custom from "@/views/CustomizationPage.vue";
import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/HomePage.vue";
// import Landing from "@/views/LandingPage.vue";
// import PresetPage from "@/views/PresetPage.vue";
// import PresetsOverview from "@/views/PresetsOverview.vue";
// import PrintPage from "@/views/PrintPage.vue";
// import PrintsOverview from "@/views/PrintsOverview.vue";
// import ProductPage from "@/views/ProductPage.vue";
// import ProductsOverview from "@/views/ProductsOverview.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: Home,
    },

    {
        path: '/a/:alias',
        name: 'AlbumPage',
        component: Album,
    },

    {
        path: '/account',
        name: 'AccountPage',
        component: Account,
    },

    {
        path: '/contact',
        name: 'ContactPage',
        component: Contact,
    },

    {
        path: '/shoots',
        name: 'AlbumsPage',
        component: Albums,
    },
	
    {
        path: "/:alias",
        name: "DefaultPage",
        component: DefaultPage,
    },
];

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        return savedPosition ? savedPosition : { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
