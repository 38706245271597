<template>
    <span class="toggle-switch" :class="status ? 'on' : 'off'" @click="toggle">
        <div class="switch"></div>
    </span>
</template>


<script>
var vm;

export default {
    props: {
        resourceID: Number,
        status: Boolean,
    },


    methods: {
        toggle()
        {
            vm.$emit('update', !vm.status, vm.resourceID)
        }
    },


    created()
    {
        vm = this;
    },
}
</script>