<template>
    <main>
        <section class="columns">
            <h1>
                {{ page.title }}
            </h1>

            <form class="user">
                <h2>
                    Mijn gegevens
                </h2>

                <ul>
                    <li :class="data.name != user.name ? 'updated' : ''">
                        <input type="text" placeholder="Naam..." v-model="data.name" :readonly="!edit" :tabindex="edit ? 1 : -1" />
                    </li>
                    
                    <li :class="data.email != user.email ? 'updated' : ''">
                        <input type="email" placeholder="E-mail..." v-model="data.email" :readonly="!edit" :tabindex="edit ? 1 : -1" />
                    </li>
                    
                    <li :class="data.address != user.address ? 'updated' : ''">
                        <input type="text" placeholder="Adres..." v-model="data.address" :readonly="!edit" :tabindex="edit ? 1 : -1" />
                    </li>
                    
                    <li :class="data.postcode != user.postcode ? 'updated' : ''">
                        <input type="text" placeholder="Postcode..." v-model="data.postcode" :readonly="!edit" :tabindex="edit ? 1 : -1" />
                    </li>
                    
                    <li :class="data.city != user.city ? 'updated' : ''">
                        <input type="text" placeholder="Plaats..." v-model="data.city" :readonly="!edit" :tabindex="edit ? 1 : -1" />
                    </li>

                    <li>
                        <a href="#" @click.prevent="edit = !edit" class="button" :class="{ 'primary': edit }">
                            {{ btnText }}
                        </a>
                    </li>
                </ul>
            </form>

            <div>
                <h2>
                    Mijn shoots
                </h2>

                <ul>
                    <li v-for="(a, id) in userAlbums" :key="id">
                        <router-link :to="{ name: 'AlbumPage', params: { alias: a.alias }}">
                            {{ a.pagetitle }} ({{ getDate(a) }})
                        </router-link>
                    </li>
                </ul>
            </div>
        </section>
    </main>
</template>


<script>
import axios from 'axios';

import { mapGetters, mapActions } from 'vuex';

var vm;

export default {
    props: {
        page: Object,
    },


    data()
    {
        return {
            data: {},
            edit: false,
            processed: false,
            submitted: false,
            success: null,
        }
    },


    computed: {
        ...mapGetters([
            'albums',
            'base',
            'user',
        ]),


        btnText()
        {
            return vm.edit ? 'Opslaan' : 'Bewerken'
        },


        userAlbums()
        {
            let ua = [];

            for (let i = 0; i < vm.albums.length; i++)
            {
                let a = vm.albums[i];

                if (a.variables.client == vm.user.id)
                {
                    ua.push(a);
                }
            }

            ua.reverse();

            return ua;
        },
    },


    methods: {
        ...mapActions([
            'updateUser',
        ]),


        async saveUser()
		{
			vm.submitted = true;
            
            let formData = {
                auth: localStorage.getItem('client'),
                fields: btoa(JSON.stringify(vm.data)),
            };
            
            
            await axios.post(vm.base + 'user/update', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                vm.updateUser(response.data.results.user);
                vm.processed = true;
                vm.success = response.data.results.success;
            })
            .catch(function (error)
            {
                console.log(error);
            });
		},


        getDate(a)
        {
            if(!a.variables.date) return '';

            let ds = a.variables.date.replace(' ', 'T'),
                date = new Date(ds),
                locale = 'nl-NL';
            
            return [
                date.toLocaleDateString(locale, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }),
                date.toLocaleTimeString(locale, {
                    hour: '2-digit',
                    minute: '2-digit',
                })
            ].join('; ')
        },
    },


    created()
    {
        vm = this

        vm.data = JSON.parse(JSON.stringify(vm.user))
    },


    watch: {
        "data.postcode": {
            handler(to)
            {
                vm.data.postcode = to.replace(' ', '').toUpperCase();
            },
            deep: true
        },


        edit(to)
        {
            if (!to)
            {
                vm.saveUser();
            }
        },
    }
}
</script>